<template>
  <div>
    <section>
      <hero-global title="Iniciativas CERMI" first-width="78" last-width="22" img="/img/initiative-cermi/initiative-cermi.svg" />
    </section>

    <section class="menu-anclas container">
      <router-link :class="[this.$route?.params?.slug === 'ambitos-actuacion' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" :to="{name:'iniciativas-cermi', params:{slug:'ambitos-actuacion'}}" @click="scrollSection('ambitos-actuacion')">Ámbitos de Actuación
      </router-link>
      <router-link :class="[this.$route?.params?.slug === 'acciones-internacionales' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" :to="{name:'iniciativas-cermi', params:{slug:'acciones-internacionales'}}" @click="scrollSection('acciones-internacionales')">Acciones internacionales
      </router-link>
      <template v-if="store.projects">
        <router-link v-for="(project, key) in store.projects" :key="key" :class="[this.$route?.params?.slug === project.slug ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" :to="{name:'iniciativas-cermi', params:{slug: project.slug}}" @click="scrollSection(project.slug)">
          {{ project.name }}
        </router-link>
      </template>
    </section>
    <div class="container">
      <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive" v-model="menuAnclas" :activeMenuTop="menuTopAnclas">
        <option value="ambitos-actuacion">
          Ámbitos de Actuación
        </option>
        <option value="acciones-internacionales">
          Acciones internacionales
        </option>
        <template v-if="store.projects">
          <option v-for="(project, key) in store.projects" :key="key" :value="project.slug">
            {{ project.name }}
          </option>
        </template>
      </select>
    </div>

    <section class="p-2-rem container" id="ambitos-actuacion">
      <h2 class="title-grand title__double-center" data-text="Ámbitos de Actuación">Ámbitos de Actuación</h2>
      <br />
      <br />
      <div class="grid-full">
        <div class="grid right">
          <!-- <div>
              <ribbon text="Ámbitos de Actuación en lectura fácil" />
            </div>
            <br /> -->
          <iframe width="620" height="320" src="https://www.youtube.com/embed/byLT1CaGwMQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="grid">
          <p class="text-primary text-bold">Teniendo en cuenta la realidad social y la situación objetiva de las
            personas con discapacidad, la
            atención se concentra preferentemente en las esferas que generan más factores de exclusión para estos
            ciudadanos, determinando un menor grado de desarrollo personal y social y unas menores posibilidades de
            acceso a los derechos, bienes y servicios de la comunidad.</p>
          <br />
          <br />
          <div>
            <h3 class="subtitle">Las necesidades y demandas preferentes de las personas con discapacidad son:</h3>
          </div>
          <div class="accordion box-content">
            <div class="content w-100">
              <ul class="list-item list">
                <li :aria-expanded="this.currentShow == 'aspace-1'">
                  <input @click="toggle('aspace-1')" type="checkbox" name="list" id="aspace-1" />
                  <label for="aspace-1" class="font-family-roboto m-y-1-rem">
                    1. La no discriminación y la igualdad de oportunidades.
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-1'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>Las personas con discapacidad, globalmente consideradas, siguen sufriendo discriminaciones por
                        razón de su discapacidad, hecho que supone una vulneración de los derechos que debe tener
                        garantizado todo ciudadano, con independencia de sus circunstancias personales.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-2'">
                  <input @click="toggle('aspace-2')" type="checkbox" name="list" id="aspace-2" />
                  <label for="aspace-2" class="font-family-roboto m-y-1-rem">
                    2. La accesibilidad universal, el diseño para todos y la eliminación de barreras de todo
                    tipo. </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-2'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>La accesibilidad universal, el diseño para todos y la eliminación de barreras de todo tipo porque
                        las barreras físicas, de comunicación y mentales son una limitación a las posibilidades de
                        actuación de las personas con discapacidad, que se añaden a la situación de desventaja objetiva de
                        la que parten, impidiendo su participación plena en el curso ordinario de la vida social.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-3'">
                  <input @click="toggle('aspace-3')" type="checkbox" name="list" id="aspace-3" />
                  <label for="aspace-3" class="font-family-roboto m-y-1-rem">
                    3. El empleo.</label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-3'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>El empleo, pues sin empleo y sin su presupuesto previo, formación, no es posible la incorporación
                        a una vida activa que haga que las personas con discapacidad dejen de ser sujetos dependientes y
                        se conviertan en los dueños de su propio destino.El desempleo -con índices que superan hasta tres
                        y cuatro veces a los del resto de la población- y la escasa tasa de participación (inactividad
                        laboral), es uno de los principales problemas que sufren las personas con discapacidad y que
                        genera una espiral de marginación y dependencia.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-4'">
                  <input @click="toggle('aspace-4')" type="checkbox" name="list" id="aspace-4" />
                  <label for="aspace-4" class="font-family-roboto m-y-1-rem">
                    4. La educación.</label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-4'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>La educación es un factor de suma importancia para el desarrollo personal de las personas con
                        discapacidad, que condiciona la posibilidad de acceder a otras vías de reconocida normalización
                        como es la del empleo.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-5'">
                  <input @click="toggle('aspace-5')" type="checkbox" name="list" id="aspace-5" />
                  <label for="aspace-5" class="font-family-roboto m-y-1-rem">5. La fiscalidad.</label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-5'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>La fiscalidad, entendida como herramienta de acompañamiento de las políticas activas de
                        normalización y participación sociales.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-6'">
                  <input @click="toggle('aspace-6')" type="checkbox" name="list" id="aspace-6" />
                  <label for="aspace-6" class="font-family-roboto m-y-1-rem">6. La salud.</label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-6'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>La salud, la asistencia sanitaria y la protección del consumidor con discapacidad, pues el
                        desarrollo personal y la plena participación social resultan imposibles si no se atienden las
                        singularidades que las personas con discapacidad presentan en estos ámbitos.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-7'">
                  <input @click="toggle('aspace-7')" type="checkbox" name="list" id="aspace-7" />
                  <label for="aspace-7" class="font-family-roboto m-y-1-rem">7. La protección y la previsión
                    sociales.</label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-7'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>La protección y la previsión sociales, porque las personas con discapacidad, necesitadas de
                        medidas enérgicas de fomento del empleo y de la incorporación al mundo del trabajo de la persona
                        con discapacidad, no renuncian al fortalecimiento y mejora de los sistemas de protección social
                        actualmente existentes, que garanticen la situación de los que no pueden acceder o mantenerse en
                        el mercado laboral.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-8'">
                  <input @click="toggle('aspace-8')" type="checkbox" name="list" id="aspace-8" />
                  <label for="aspace-8" class="font-family-roboto m-y-1-rem">
                    8. Las personas con discapacidad más severamente afectadas y las que no se pueden representar a sí
                    mismas.
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-8'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>Las personas con discapacidad más severamente afectadas y las que no se pueden representar a sí
                        mismas, pues dentro de este grupo de población, son precisamente éstas las que se encuentran
                        objetivamente en una situación más vulnerable y de mayor necesidad, hecho que reclama la atención
                        preferente no sólo de los poderes públicos y la sociedad, sino y sobre todo del propio movimiento
                        asociativo.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-9'">
                  <input @click="toggle('aspace-9')" type="checkbox" name="list" id="aspace-9" />
                  <label for="aspace-9" class="font-family-roboto m-y-1-rem">
                    9. La oportunidad digital.
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-9'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>La oportunidad digital (nuevas tecnologías y sociedad del conocimiento), pues estos factores están
                        determinando y determinarán aún más la sociedad del futuro, de la que las personas con
                        discapacidad no pueden quedar excluidas.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-10'">
                  <input @click="toggle('aspace-10')" type="checkbox" name="list" id="aspace-10" />
                  <label for="aspace-10" class="font-family-roboto m-y-1-rem">
                    10. Las mujeres con discapacidad.
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-10'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>Las mujeres con discapacidad, dado que este segmento no sólo es el más numeroso dentro del grupo
                        de las personas con discapacidad, sino que también es el que sufre una mayor discriminación, pues
                        al factor de relegación por su discapacidad, se añade el de relegación por género, común en todas
                        las esferas de nuestra sociedad.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-11'">
                  <input @click="toggle('aspace-11')" type="checkbox" name="list" id="aspace-11" />
                  <label for="aspace-11" class="font-family-roboto m-y-1-rem">
                    11. Las familias de personas con discapacidad.
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-11'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>Las familias de personas con discapacidad, que siguen soportando muchas veces en exclusiva y sin
                        el debido grado de protección social el esfuerzo suplementario que supone, en todos los órdenes,
                        la atención a una persona con discapacidad, sobre todo, cuando ésta es gravemente afectada.
                        Incluso, dentro de la propia familia, la atención a las personas con discapacidad no es
                        equitativa, sino que recae preferentemente en las mujeres (en las madres, las hermanas, las
                        esposas o parejas, etc.), lo que dificulta enormemente la posibilidad de que estas mujeres puedan
                        llevar una vida social plena.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-12'">
                  <input @click="toggle('aspace-12')" type="checkbox" name="list" id="aspace-12" />
                  <label for="aspace-12" class="font-family-roboto m-y-1-rem">
                    12. Las personas con discapacidad en el medio rural.
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-12'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>Las personas con discapacidad en el medio rural, ya que este segmento está lejos de poder alcanzar
                        los niveles de acceso a derechos, prestaciones, bienes y servicios a los que de ordinario sí
                        accede una persona con discapacidad residente en un área urbana.</p>
                    </div>
                  </div>
                </li>
                <li :aria-expanded="this.currentShow == 'aspace-13'">
                  <input @click="toggle('aspace-13')" type="checkbox" name="list" id="aspace-13" />
                  <label for="aspace-13" class="font-family-roboto m-y-1-rem">
                    13. La dimensión europea y cooperación internacional.
                  </label>
                  <div class="inner-container" :show="this.currentShow == 'aspace-13'">
                    <hr class="hr color-black" />
                    <div class="p-2">
                      <p>La dimensión europea y cooperación internacional, en atención a que la realidad discapacidad,
                        en un entorno globalizado, transciende los límites nacionales, para generar espacios más amplios
                        de acción y colaboración en favor de las personas con discapacidad.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container text-center slider-fotos-iniciativas">
      <agile :options="myOptions" ref="carousel" class="carrousel-2 carrousel-home container ">
        <div>
          <img class="d-block" src="/img/initiative-cermi/initiative-cermi_1.png" alt="Ámbitos de Actuación." />
        </div>
        <div>
          <img class="d-block" src="/img/initiative-cermi/initiative-cermi_2.png" alt="Ámbitos de Actuación." />
        </div>
        <div>
          <img class="d-block" src="/img/initiative-cermi/initiative-cermi_3.png" alt="Ámbitos de Actuación." />
        </div>
        <div>
          <img class="d-block" src="/img/initiative-cermi/initiative-cermi_4.png" alt="Ámbitos de Actuación." />
        </div>
      </agile>
    </section>

    <section class="background-blue-3" id="acciones-internacionales">
      <div class="container">
        <div class="column-center">
          <h2 class="title-grand title__double-center text-primary-light" data-text="Acciones internacionales">Acciones
            internacionales</h2>
        </div>
        <p class="w-50 text-center margin-auto subtitle">"La reforma de los Tratados constitutivos de la Unión Europea ha llevado a la consagración legal del principio de no discriminación de las personas con discapacidad."</p>
        <div class="grid-full">
          <div class="grid right">
            <ribbon text="Acción internacional" />
          </div>
          <div class="grid " v-html="$t('easyReader.acciones_internacionales.text.'+this.store.easyRead.active)">
          </div>

        </div>

      </div>

    </section>
    <br />
    <br />
    <div id="proyectos">
      <template v-if="store.projects">
        <section v-for="(project, key) in store.projects" :key="key" :id="project.slug" class="container">
          <blue-card :title="project.name" :img="project.logo.url" :text="project.description" btn-text="Saber más" :idSlug="project.slug" />
        </section>
      </template>
    </div>
    <section>
      <carrousel2 class="container" :dataContent="$tm('translations.carrouselData.cermi')" />
    </section>
    <section>
      <other-links-interest class="links-interest-box container" />
    </section>
    <br />
    <br />

  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import Ribbon from "@/components/Global/Ribbon";
  import { VueAgile } from "vue-agile";
  import BlueCard from "@/components/Card/BlueCard";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import { contentStore } from '@/stores/contents'

  export default {
    name: "InitiativeCermi",
    components: { OtherLinksInterest, Carrousel2, BlueCard, Ribbon, HeroGlobal, agile: VueAgile },
    setup() {
      const store = contentStore();
      store.loadProjects();
      return {
        store
      }
    },
  data: () => ({
      currentShow: null,
      menuAnclas: 'ambitos-actuacion',
      menuTopAnclas: false,
      myOptions: {
        responsive: [{
          breakpoint: 900,
          settings: {
            navButtons: true,
            slidesToShow: 4,
            dots: false,
            centerMode: true,
            unagile: false,
          },
        }, {
          breakpoint: 800,
          settings: {
            navButtons: true,
            slidesToShow: 3,
            dots: false,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 700,
          settings: {
            navButtons: true,
            slidesToShow: 2,
            dots: false,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            navButtons: true,
            slidesToShow: 1,
            dots: false,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            navButtons: true,
            slidesToShow: 1,
            dots: false,
            autoplay: false,
          },
        },],
      },
    }),
    mounted() {
      if (this.$route.hash) {
        this.menuAnclas = this.$route.hash ? this.$route.hash : 'ambitos-actuacion'
        let element = this.$route.hash.replace('#', '')
        const offsetTop = document.getElementById(element).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      }
      this.topMenuAnclas()
    },
    methods: {
      setSection() {
        this.menuAnclas = this.$route?.params?.slug ? this.$route?.params?.slug : 'organigrama'
        if (this.$route?.params?.slug) {
          let element = this.$route?.params?.slug.replace('#', '')
          element !== null && (() => {
            const offsetTop = document.getElementById(element).offsetTop - 150;
            setTimeout(function () {
              scroll({
                behavior: "smooth",
                top: offsetTop,
              });
            }, 200);
          })()
        }
        else {
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: 0,
            });
          }, 200);
        }
      },
      topMenuAnclas: function () {
        window.onscroll = () => {
          if (window.scrollY > 0) {
            this.menuTopAnclas = true;
          } else {
            this.menuTopAnclas = false
          }
        }
      },
      toggle(id) {
        if (this.currentShow === id) {
          this.currentShow = null;
        } else {
          this.currentShow = id;
        }
      }
    },
    watch: {
      "$route"() {
        // this.setSection()
        if (this.$route.hash) {
          this.menuAnclas = this.$route.hash.replace('#', '')
          let element = this.$route.hash.replace('#', '')
          const offsetTop = document.getElementById(element).offsetTop - 150;
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: offsetTop,
            });
          }, 200);
        }
        
      },
      "menuAnclas"() {
        this.$router.push({
          params: {
            name: 'iniciativas-cermi',
            slug: this.menuAnclas,
          }
        });
        // let element = this.$route.hash.replace('#', '')
        // const offsetTop = document.getElementById(this.menuAnclas).offsetTop - 150;
        // setTimeout(function () {
        //   scroll({
        //     behavior: "smooth",
        //     top: offsetTop,
        //   });
        // }, 200);
      },

    }
  }
</script>

<style scoped lang="scss">
  @import "../styles/colours";

  #acciones-internacionales {
    padding: 60px 0;
    margin: 40px 0;
  }

  .box-content {
    background: transparent;

    .inner-container {
      padding-left: 0 !important;

      ul {
        li {
          border-bottom: none;
        }
      }
    }

    li {
      padding: 0 !important;
    }

    label::before,
    .item-content.-one::before {
      bottom: -10px;
      right: 0;
    }
  }

  .carrousel-home {
    img {
      width: 95%;
      height: 230px;
      object-fit: cover;
      object-position: center center;
      border-radius: 30px;
    }
  }
</style>