<template>
  <div class="ribbon" @click="toggleReaderEasy()" :title="'Leer '+ this.store.easyRead.text+''">
    <div class="ribbon__icon"></div>
    <div class="ribbon__text">
      <p>{{ text }} {{this.store.easyRead.text}}</p>
    </div>
  </div>
</template>

<script>
  import { contentStore } from '@/stores/contents'
  export default {
    name: "Ribbon",
    props: ['width', 'text'],
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    methods: {
      toggleReaderEasy: function () {
        this.store.easyRead.active = !this.store.easyRead.active;
        if (this.store.easyRead.active) {
          this.store.easyRead.text = 'en lectura normal'
        } else {
          this.store.easyRead.text = 'en lectura fácil'
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../styles/colours";

  .ribbon {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .ribbon__icon {
      display: block;
      background-color: #333366;
      width: 75px;
      height: 75px;
      border-radius: 37.5px;
      z-index: 2;
      background-image: url("/img/ribbon/ribbon-1.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    .ribbon__text {
      position: absolute;
      display: table;
      background-color: #D6E2F2;
      width: calc(100% - 75px);
      height: 50px;
      border-radius: 0 30px 30px 0;
      right: calc(0% + 10px);
      z-index: 1;

      p {
        display: table-cell;
        vertical-align: middle;
        padding-left: 1rem;
        font-weight: bold;
        color: $blue;
      }
    }
  }
</style>