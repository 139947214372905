<template>
  <section class="position-relative p-y-3-rem blue-card">
    <div class="background-primary text-white p-2-rem border-radius-20-px">
      <div class="w-50">
        <p class="title">{{title}}</p>
        <article class="m-bottom-1-rem text-light font-family-roboto font-size-16">
          {{text}}
        </article>
        <router-link :to="{name:'proyectos-ficha-cermi',
        params:{
          slug: idSlug
        }}" title="Ver más Exposiciones" alt="Ver más exposiciones" class="btn btn-blue outline">{{btnText}}</router-link>

      </div>
    </div>
    <div class="w-30 position-absolute top-0 right-0">
      <img class="d-block  margin-auto img-round" :alt="title" :title="title" :src="img" />
    </div>
  </section>
</template>

<script>
  export default {
    name: "BlueCard",
    props: ['title', 'text', 'btnText', 'img', 'callback', 'idSlug']
  }
</script>
<style>
  .blue-card {
    margin: 30px 0;
  }

  .img-round {
    width: auto;
    height: 235px;
    border-radius: 30px;
    object-fit: cover;
    margin-top: 25px;
  }
</style>